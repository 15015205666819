.login_page {
  background: url("/public/Images/adot-login.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.signup_page {
  position: relative;
  background: url("/public/Images/adot-signup-background-color.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.signup_page_background {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: url("/public/Images/adot-signup-cactus.jpg") no-repeat right;
  background-size: contain;
}

.authen_wrapper {
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 40px;
  width: 100px;
  height: 100px;
}

.name {
  font-size: 60px;
  font-weight: 900;
}

.form {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
}

.form_input {
  border-width: 0 !important;
  padding: 10px 7px;
  /* width: 50vw; */
  /* max-width: 300px; */
  width: 300px;
}

.icon {
  font-size: 18px;
  margin-right: 10px;
}

.line {
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 5px 5%;
}

.authen_button {
  width: 310px;
  margin-top: 30px;
  padding: 8px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
}

.text {
  color: #141D49;
  font-weight: 700;
}

.agree_terms {
  margin-top: 20px;
  font-size: 12px;
}

.open_terms_btn {
  cursor: pointer;
  font-size: 12px;
}

.open_terms_btn:hover {
  text-decoration: underline;
}

.signup_link {
  margin-top: 20px;
}

.terms_popup_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 500;
}

.terms_box {
  margin: 5% auto;
  background-color: white;
  color: #141D49;
  padding: 30px 20px 20px 30px;
  border-radius: 15px;
  position: relative;
  width: 90%;
  max-width: 600px;
  height: calc(90vh - 50px);
  overflow: auto;
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 28px;
  height: 27px;
  cursor: pointer;
}

.close_button::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: black;
  border-radius: 4px;
  transform: translateY(9px) translateX(1px) rotate(45deg);
}

.close_button::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: black;
  border-radius: 4px;
  transform: translateY(9px) translateX(1px) rotate(-45deg);
}

.close_button:hover::before, .close_button:hover::after {
  background-color: #666;
}

.terms_title {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.terms_header {
  font-size: 14px;
  font-weight: 700;
}

.terms_text {
  text-align: justify;
}

.change_password_form {
  width: 310px;
  border: solid 1px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
  .signup_page_background {
    display: none;
  }
}