.org_item_wrapper {
  justify-content: space-between;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
}

.org_item_wrapper:hover {
  background-color: #cacaca;
}

.org_item_logo {
  height: 35px;
  margin-right: 10px;
}

.org_item_name {
  font-size: 16px;
  font-weight: 700;
  color: #141D49;
}

.org_item_num_of_mem {
  font-weight: 300;
  color: #141D49;
}