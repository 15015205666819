.main_layout {
  position: relative;
  width: 100vw;
  z-index: 100;
}

.header {
  height: 50px;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: space-between;
  color: #141D49;
  background-color: #FBFBFB;
  padding: 0 20px;
  z-index: 10000;
}

.organization {
  height: 100%;
  display: flex;
  align-items: center;
}

.organization_logo {
  height: 35px;
}

.organization_name {
  font-weight: 700;
  font-size: 30px;
  color: #141D49;
  margin: 0 0 0 10px;
}

.account {
  height: 100%;
  display: flex;
  align-items: center;
}

.account_avatar {
  cursor: pointer;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 15px;
  /* background-color: #e9e9e9; */
  border: solid 5px rgba(0, 0, 0, 0);
  /* padding: 5px; */
}

.account_avatar:hover {
  border: solid 5px rgba(210, 210, 210, 0.5);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
}

.menu_item {
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  color: #141D49 !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: normal;
}

.noti_item {
  padding: 10px;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
}

.behind_header {
  height: 50px;
  width: 100%;
}

.navigation_tree {
  margin: 40px 0 20px 25px;
  /* font-size: 13px; */
  font-weight: 600;
  display: flex;
}

.link {
  text-decoration: none;
  color: #141D49;
}

.current_nav {
  padding-bottom: 10px;
  border-bottom: 2px solid #1968FC;
}

.content {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .header {
    padding: 0 10px;
  }

  .organization_name {
    font-size: 20px;
  }

  .account_avatar {
    min-width: 35px;
    height: 35px;
    margin-left: 10px;
  }
}