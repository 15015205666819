.home_wrapper {
  width: 100%;
  padding: 20px;
  padding-bottom: 100px;
}

.card_title {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
}

.card_number {
  margin: 15px 0;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: '#FFFFFF';
}

.line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 10px 10px;
}

.link_in_option_menu {
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  font-weight: 700;
  color: #141D49 !important;
  display: flex;
  align-items: center;
  line-height: normal;
}