.stream_list_page {
  padding: 25px;
  padding-bottom: 100px;
}

.page_title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 35px;
}

.link_in_title {
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 1px;
  color: #1968FC;
}

.group_filter_label {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-right: 60px;
  cursor: pointer;
  border-bottom: #00000000 3px solid;
}

.stream_card {
  padding: 20px;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: solid 1px rgba(0, 0, 0, 0.25);
  color: #141D49 !important;
}

.stream_card_description {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 24px;
  height: 72px;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.create_stream_page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_label {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0 5px 0;
}

.form_button_submit {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  text-transform: uppercase;
  height: 45px;
}

.stream_detail_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stream_detail {
  max-width: 1350px;
  width: 100vw;
  padding: 20px 50px;
}

.creator_avatar {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.creator_name {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;
}

.content_label {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.selected_stream_label {
  margin: 20px 0 10px 0;
}

.selected_stream_content {
  font-weight: 700;
}