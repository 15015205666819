.ant-modal-content {
  border-radius: 15px;
}

.ant-modal-content button {
  border-radius: 8px;
}

a {
  text-decoration: none;
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.75) !important;
}